.stroke {
  position: relative;
  z-index: 1;
}
nav {
    width: 80%;
    margin: 0 auto;
    height: '80px';
    // box-shadow: 0px 5px 0px #bacddb;
}
nav ul {
    list-style: none;
    text-align: center;
    padding: 0.2vw 0;

}
nav ul li {
    display: inline-block;
}
nav ul li a {
    display: block;
    padding: 15px;
    text-decoration: none;
    color: #bacddb;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 10px;
}
nav ul li a,
nav ul li a:after,
nav ul li a:before {
    transition: all .5s;
}
nav ul li a:hover {
    color: #92b7d3;
}

nav.stroke ul li a {
  position: relative;
}
nav.stroke ul li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: '.';
  color: transparent;
  background: #2d526d;
  height: 1px;
}
nav.stroke ul li a:hover:after {
  width: 100%;
}
